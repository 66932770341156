import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import axios from 'axios';

// Styles
import './styles.scss';

// Components
import Loader from '../../../components/Loader';
import Content from '../../../components/Content';
import LinkBox from '../../../components/LinkBox';
import ProcessInformationBanner from '../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../components/ProcessSideContent';
import formatPrice from '../../../utils/formatPrice';
import ContentBox from '../../../components/ContentBox';
import FormInput from '../../../components/FormInput';
import FormCheckbox from '../../../components/FormCheckbox';
import FormSelect from '../../../components/FormSelect';

function CustomProcessesPdfSchuetzerView() {

	let navigate = useHistory();
	const [showLoader, setShowLoader] = useState(false);

	const [inputFile, setInputFile] = useState(null);

	const [checkboxWatermark, setCheckboxWatermark] = useState(false);
	const [inputWatermark, setInputWatermark] = useState("PROTECTED");

	const [checkboxPassword, setCheckboxPassword] = useState(false);
	const [inputPassword, setInputPassword] = useState("");

	const securePdf = () => {
		setShowLoader(true);

		// Upload file to server by using axios (disable timeout!)
		const formData = new FormData();
		formData.append("pdfFile", inputFile);
		formData.append("useWatermark", checkboxWatermark);
		formData.append("watermarkText", inputWatermark);
		formData.append("usePassword", checkboxPassword);
		formData.append("password", inputPassword);

		axios.post(`${process.env.REACT_APP_API_URL}/api/pdf-protector`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
			timeout: 0,
			responseType: 'blob'
		})
			.then((res) => {
				setShowLoader(false);
				
				// Stream the PDF file to the user
				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', "OUTPUT.pdf");
				document.body.appendChild(link);
				link.click();

				
			})
			.catch((error) => {
				setShowLoader(false);
				navigate.push("/status/error");
			});

	}

	return (
		<>
			{showLoader && (
				<Loader text="PDF wird geschützt. Dies kann bis zu 5 Minuten dauern!" />
			)}
			<Content>
				<ProcessInformationBanner
					image="pdf.jpg"
					title="PDF-Schützer"
					text="Schützen Sie Ihre PDF-Dateien vor unbefugtem Zugriff & Bearbeitung."

					hasCancelButton={true}
					cancelButtonAction={() => {
						navigate.push("/");
					}}

					hasSubmitButton={true}
					submitButtonTitle="PDF schützen"
					submitButtonAction={securePdf}
				/>

				<ProcessSideContent>
					<ContentBox>
						<FormInput
							label="PDF hochladen"
							required
							type="file"
							onChange={(event) => setInputFile(event.target.files[0])}
							accept="application/pdf"
						/>
					</ContentBox>

					<ContentBox>
						<FormCheckbox
							label="Wasserzeichen hinzufügen"
							checked={checkboxWatermark}
							onChange={(e) => {
								setCheckboxWatermark(e);
							}}
							text="Wasserzeichen verwenden"
						/>
						{checkboxWatermark && (
							<FormInput
								label="Wasserzeichen"
								placeholder="Wasserzeichen Text"
								onChange={(event) => setInputWatermark(event.target.value)}
								value={inputWatermark}
							/>
						)}
					</ContentBox>

					<ContentBox>
						<FormCheckbox
							label="Passwort hinzufügen"
							checked={checkboxPassword}
							onChange={(e) => {
								setCheckboxPassword(e);
							}}
							text="Passwort verwenden"
						/>
						{checkboxPassword && (
							<FormInput
								label="Passwort"
								placeholder="Passwort"
								onChange={(event) => setInputPassword(event.target.value)}
								value={inputPassword}
							/>
						)}
					</ContentBox>
				</ProcessSideContent>

			</Content>
		</>
	);
}

export default CustomProcessesPdfSchuetzerView;