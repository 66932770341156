import React, { useState, useId } from "react";

// Styles
import "./styles.scss";

function FormCheckbox(props) {
  const htmlForId = useId();
  const [checked, setChecked] = useState(props.checked);

  const handleChange = () => {
    let newChecked = !checked;
    setChecked(newChecked);
    props.onChange(newChecked);
  };

  return (
    <div className="FormInput" {...props}>
      <label htmlFor={htmlForId} className="FormCheckbox-Container">
        <button
          id={htmlForId}
          disabled={props.disabled ? true : false}
          onClick={handleChange}
          className={`material-symbols-rounded FormCheckbox-Container-Input${
            checked ? " FormCheckbox-Container-Input--checked" : ""
          }`}
        >
          {checked && "check"}
        </button>
        {props.label}: {props.text}
      </label>
    </div>
  );
}

export default FormCheckbox;
