import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import axios from 'axios';

// Styles
import './styles.scss';

// Components
import Loader from '../../../../../components/Loader';
import Content from '../../../../../components/Content';
import ContentBox from '../../../../../components/ContentBox';
import ProcessInformationBanner from '../../../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../../../components/ProcessSideContent';
import FormInput from '../../../../../components/FormInput';
import FormSelect from '../../../../../components/FormSelect';
import FormTextarea from '../../../../../components/FormTextarea';
import FormCheckbox from '../../../../../components/FormCheckbox';

import { useStateWithCallbackLazy } from 'use-state-with-callback';

// Utils
import formatPrice from '../../../../../utils/formatPrice';

function DefaultProcessesVerwaltungZahlungsmethodenCreateView() {

	let navigate = useHistory();
	const [showLoader, setShowLoader] = useState(false);

	const [paymentName, setPaymentName] = useState("");
	const [paymentDescription, setPaymentDescription] = useState("");
	const [paymentInvoiceTextUnpaid, setPaymentInvoiceTextUnpaid] = useState("");
	const [paymentInvoiceTextPaid, setPaymentInvoiceTextPaid] = useState("");
	const [paymentDirectPayment, setPaymentDirectPayment] = useState(false);
	const [paymentDays, setPaymentDays] = useState(7);
	const [paymentAllowGirocode, setPaymentAllowGirocode] = useState(false);

	const [paymentNameError, setPaymentNameError] = useState(false);
	const [paymentDescriptionError, setPaymentDescriptionError] = useState(false);
	const [paymentInvoiceTextUnpaidError, setPaymentInvoiceTextUnpaidError] = useState(false);
	const [paymentInvoiceTextPaidError, setPaymentInvoiceTextPaidError] = useState(false);
	const [paymentDaysError, setPaymentDaysError] = useState(false);
	const [paymentAllowGirocodeError, setPaymentAllowGirocodeError] = useState(false);

	const resetAllErrors = () => {
		setPaymentNameError(false);
		setPaymentDescriptionError(false);
		setPaymentInvoiceTextUnpaidError(false);
		setPaymentInvoiceTextPaidError(false);
		setPaymentDaysError(false);
		setPaymentAllowGirocodeError(false);
	}

	const createPaymentMethod = () => {

		resetAllErrors();
		setShowLoader(true);

		/*
			REQUIRED FIELDS:
			paymentName
			paymentDescription
			paymentInvoiceTextUnpaid
			paymentInvoiceTextPaid
			paymentDays
			paymentAllowGirocode
		*/		

		/*
			Possible errors:
			paymentNameError (max 60 characters, required)
			paymentDescriptionError (max 255 characters, optional)
			paymentInvoiceTextUnpaidError (max 255 characters, required)
			paymentInvoiceTextPaidError (max 255 characters, required)
			paymentDaysError (required, must be a number, min 0, max 365)
			paymentAllowGirocodeError (required, must be a boolean)
		*/
		if (paymentName.length === 0 || paymentName.length > 60) {
			setPaymentNameError(true);
			return;
		}
		if (paymentDescription.length > 255) {
			setPaymentDescriptionError(true);
			return;
		}
		if (paymentInvoiceTextUnpaid.length === 0 || paymentInvoiceTextUnpaid.length > 255) {
			setPaymentInvoiceTextUnpaidError(true);
			return;
		}
		if (paymentInvoiceTextPaid.length === 0 || paymentInvoiceTextPaid.length > 255) {
			setPaymentInvoiceTextPaidError(true);
			return;
		}
		if (isNaN(paymentDays) || paymentDays < 0 || paymentDays > 365) {
			setPaymentDaysError(true);
			return;
		}
		if (paymentAllowGirocode !== true && paymentAllowGirocode !== false) {
			setPaymentAllowGirocodeError(true);
			return;
		}

		if (
			!paymentNameError &&
			!paymentDescriptionError &&
			!paymentInvoiceTextUnpaidError &&
			!paymentInvoiceTextPaidError &&
			!paymentDaysError &&
			!paymentAllowGirocodeError
		) {
			axios.post(`${process.env.REACT_APP_API_URL}/api/payment-methods`, {
				payment_method_name: paymentName,
				payment_method_description: paymentDescription,
				payment_method_invoice_text: paymentInvoiceTextUnpaid,
				payment_method_paid_invoice_text: paymentInvoiceTextPaid,
				payment_method_direct_payment: paymentDirectPayment,
				payment_method_payment_days: paymentDays,
				payment_method_allow_girocode: paymentAllowGirocode
			}).then((res) => {
				setShowLoader(false);
				navigate.push("/default-processes/verwaltung/zahlungsmethoden");
			})
		}

	}

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="developer-1.jpg"
					title="Verwaltung"
					text="Verwalten Sie Zahlungsmethoden in Ihrem Unternehmen."

					hasCancelButton={true}
					cancelButtonAction={() => {
						navigate.push("/default-processes/verwaltung/zahlungsmethoden");
					}}

					hasSubmitButton={true}
					submitButtonTitle="Zahlungsmethode anlegen"
					submitButtonAction={() => {
						createPaymentMethod()
					}}
				/>

				<ProcessSideContent>
					<ContentBox
						title="Daten"
					>
						<FormInput
							label="Name"
							value={paymentName}
							onChange={(e) => {
								setPaymentName(e.target.value);
							}}
							required
							error={paymentNameError}
						/>
						<FormInput
							label="Beschreibung"
							value={paymentDescription}
							onChange={(e) => {
								setPaymentDescription(e.target.value);
							}}
							error={paymentDescriptionError}
						/>
						<FormInput
							label="Rechnungstext unbezahlt"
							value={paymentInvoiceTextUnpaid}
							onChange={(e) => {
								setPaymentInvoiceTextUnpaid(e.target.value);
							}}
							required
							error={paymentInvoiceTextUnpaidError}
						/>
						<FormInput
							label="Rechnungstext bezahlt"
							value={paymentInvoiceTextPaid}
							onChange={(e) => {
								setPaymentInvoiceTextPaid(e.target.value);
							}}
							required
							error={paymentInvoiceTextPaidError}
						/>
						<FormCheckbox
							label="Direkte Zahlung"
							checked={paymentDirectPayment}
							onChange={(e) => {
								setPaymentDirectPayment(e.target.checked);
							}}
							text="Zahlungen werden direkt abgewickelt und nicht über ein Zahlungsziel."
						/>
						<FormCheckbox
							label="Girocode erlauben"
							checked={paymentAllowGirocode}
							onChange={(e) => {
								setPaymentAllowGirocode(e.target.checked);
							}}
							text="Erlauben Sie die Verwendung von Girocode für diese Zahlungsmethode."
						/>
						<FormInput
							label="Zahlungsfrist (Tage)"
							value={paymentDays}
							onChange={(e) => {
								setPaymentDays(e.target.value);
							}}
							required
							error={paymentDaysError}
							type="number"
						/>
					</ContentBox>
				</ProcessSideContent>
			</Content>
		</>
	);
}

export default DefaultProcessesVerwaltungZahlungsmethodenCreateView;