import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";

// Styles
import './styles.scss';

// Components
import Content from '../../../../components/Content';
import ProcessInformationBanner from '../../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../../components/ProcessSideContent';
import ContentBox from '../../../../components/ContentBox';
import FormInput from '../../../../components/FormInput';
import FormCheckbox from '../../../../components/FormCheckbox';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';
import Modal from '../../../../components/Modal';
import axios from 'axios';

function DefaultProcessesVerwaltungFreitextfelder() {

	let navigate = useHistory();
	const [showLoader, setShowLoader] = useState(false)

	const [formFields, setFormFields] = useState([{
		name: "",
		required: false
	}]);

	useEffect(() => {
		axios.get(`${process.env.REACT_APP_API_URL}/api/configuration/BILL_FREE_TEXT_FIELDS`)
		.then((res) => {
			if (res.data.configuration_name === "BILL_FREE_TEXT_FIELDS") {
				setFormFields(JSON.parse(res.data.configuration_value));
			} else {
				//navigate.push("/status/error")
			}
		})
		.catch((err) => {
			//navigate.push("/status/error")
		})
	}, [])

	const save = () => {
		setShowLoader(true);

		// Remove rows with empty name
		let newFormFields = [];
		formFields.forEach((field) => {
			if (field.name !== "") {
				newFormFields.push(field);
			}
		});

		axios.put(`${process.env.REACT_APP_API_URL}/api/configuration`, {
			BILL_FREE_TEXT_FIELDS: JSON.stringify(newFormFields)
		})
		.then((res) => {
			setShowLoader(false);
			if (res.data.status === "success") {
				setShowLoader(false);
				navigate.push("/status/success")
			} else {
				setShowLoader(false);
				navigate.push("/status/error")
			}
		}).catch((err) => {
			setShowLoader(false);
			navigate.push("/status/error")
		})
	}

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="developer-1.jpg"
					title="Verwaltung"
					text="Bearbeiten Sie hier die Freitextfelder die Sie bequem in Rechnungen verwenden können."
					
					hasSubmitButton={true}
					submitButtonTitle="Vorgang beenden"
					submitButtonAction={() => {
						navigate.push("/default-processes/verwaltung");
					}}

					hasCancelButton={true}
					cancelButtonAction={() => {
						navigate.push("/default-processes/verwaltung");
					}}

					actionButtons={[
						{
							title: "Speichern",
							action: () => {
								save();
							}
						}
					]}
				/>

				<ProcessSideContent>
					<ContentBox
						title="Freitextfelder"
					>
						<table style={{width:'100%',marginLeft: "-3px"}}>
							<thead>
								<tr>
									<th align='left'>Bezeichnung</th>
									<th align='right'>Pflicht?</th>
								</tr>
							</thead>
							<tbody>
								{formFields.map((field, index) => (
									<tr key={index}>
										<td>
											<FormInput
												placeholder="Name des Freitextfeldes"
												type="text"
												required={true}
												value={field.name}
												onChange={(e) => {
													let newFormFields = [...formFields];
													newFormFields[index].name = e.target.value;
													setFormFields(newFormFields);
												}}
											/>
										</td>
										<td align='right' className='FreeFieldCheckbox' style={{width:'100px',textAlign:'right'}}>
											<FormCheckbox
												noLabel
												checked={field.required}
												onChange={(e) => {
													let newFormFields = [...formFields];
													newFormFields[index].required = e;
													setFormFields(newFormFields);
												}}
												text=""
											/>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						<br />
						<Button onClick={() => {
							// Add new form field
							let newFormFields = [...formFields];
							newFormFields.push({
								name: "",
								required: false
							});
							setFormFields(newFormFields);
						}}>Neue Position</Button>
					</ContentBox>
				</ProcessSideContent>
			</Content>
		</>
	);
}

export default DefaultProcessesVerwaltungFreitextfelder;