import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";

// Styles
import './styles.scss';

// Components
import Content from '../../../../components/Content';
import ProcessInformationBanner from '../../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../../components/ProcessSideContent';
import ContentBox from '../../../../components/ContentBox';
import FormInput from '../../../../components/FormInput';
import Loader from '../../../../components/Loader';
import FormSelect from '../../../../components/FormSelect';
import axios from 'axios';

function DefaultProcessesVerwaltungZahlungsmethoden() {

	let navigate = useHistory();
	const [showLoader, setShowLoader] = useState(false)
	const [paymentMethods, setPaymentMethods] = useState([])

	useEffect(() => {
		setShowLoader(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/payment-methods`)
			.then((res) => {
				setShowLoader(false);
				setPaymentMethods(res.data || [])
			})
	}, [])

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="developer-1.jpg"
					title="Verwaltung"
					text="Verwalten Sie Zahlungsmethoden in Ihrem Unternehmen."
					
					hasSubmitButton={true}
					submitButtonTitle="Vorgang beenden"
					submitButtonAction={() => {
						navigate.push("/default-processes/verwaltung");
					}}

					actionButtons={[
						{
							title: "Zahlungsmethode anlegen",
							action: () => {
								navigate.push("/default-processes/verwaltung/zahlungsmethoden/create")
							}
						}
					]}
				/>

				<ProcessSideContent>
					<ContentBox
						title="Methoden"
					>
						{paymentMethods.map((paymentMethod, index) => (
							<ContentBox
								onClick={() => {	
									navigate.push(`/default-processes/verwaltung/zahlungsmethoden/${paymentMethod.id}`)
								}}
								style={{
									cursor: "pointer",
									marginBottom: "10px"
								}}
								key={index}
								title={paymentMethod.payment_method_name}
							>
								<p>
									{paymentMethod.payment_method_description}
								</p>
							</ContentBox>
						))}
					</ContentBox>
				</ProcessSideContent>
			</Content>
		</>
	);
}

export default DefaultProcessesVerwaltungZahlungsmethoden;