import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";

// Styles
import './styles.scss';

// Components
import Content from '../../../../components/Content';
import ProcessInformationBanner from '../../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../../components/ProcessSideContent';
import ContentBox from '../../../../components/ContentBox';
import FormInput from '../../../../components/FormInput';
import Loader from '../../../../components/Loader';
import FormSelect from '../../../../components/FormSelect';
import axios from 'axios';

function DefaultProcessesVerwaltungBelegVorlagen() {

	let navigate = useHistory();
	const [showLoader, setShowLoader] = useState(false)
	const [templateList, setTemplateList] = useState([])

	useEffect(() => {
		setShowLoader(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/configuration/document-templates`)
			.then((res) => {
				setShowLoader(false);
				setTemplateList(res.data)
			})
	}, [])

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="developer-1.jpg"
					title="Belegvorlagen"
					text="Verwalten Sie Vorlagen zu Rechnungen, Mahnungen und mehr."
					
					hasSubmitButton={true}
					submitButtonTitle="Vorgang beenden"
					submitButtonAction={() => {
						navigate.push("/default-processes/verwaltung");
					}}
				/>

				<ProcessSideContent>
					<ContentBox
						title="Vorlagen"
					>
					</ContentBox>
				</ProcessSideContent>
			</Content>
		</>
	);
}

export default DefaultProcessesVerwaltungBelegVorlagen;