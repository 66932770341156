import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import axios from 'axios';

// Styles
import './styles.scss';

// Components
import Loader from '../../../../../components/Loader';
import Content from '../../../../../components/Content';
import ContentBox from '../../../../../components/ContentBox';
import ProcessInformationBanner from '../../../../../components/ProcessInformationBanner';
import ProcessSideContent from '../../../../../components/ProcessSideContent';
import FormInput from '../../../../../components/FormInput';
import Modal from '../../../../../components/Modal';
import FormCheckbox from '../../../../../components/FormCheckbox';

function DefaultProcessesVerwaltungZahlungsmethodenDetailsView() {

	let navigate = useHistory();
	const { id } = useParams();
	const [showLoader, setShowLoader] = useState(false);

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [paymentName, setPaymentName] = useState("");
	const [paymentDescription, setPaymentDescription] = useState("");
	const [paymentInvoiceTextUnpaid, setPaymentInvoiceTextUnpaid] = useState("");
	const [paymentInvoiceTextPaid, setPaymentInvoiceTextPaid] = useState("");
	const [paymentDirectPayment, setPaymentDirectPayment] = useState(false);
	const [paymentDays, setPaymentDays] = useState(7);
	const [paymentAllowGirocode, setPaymentAllowGirocode] = useState(false);

	const [paymentNameError, setPaymentNameError] = useState(false);
	const [paymentDescriptionError, setPaymentDescriptionError] = useState(false);
	const [paymentInvoiceTextUnpaidError, setPaymentInvoiceTextUnpaidError] = useState(false);
	const [paymentInvoiceTextPaidError, setPaymentInvoiceTextPaidError] = useState(false);
	const [paymentDaysError, setPaymentDaysError] = useState(false);
	const [paymentAllowGirocodeError, setPaymentAllowGirocodeError] = useState(false);

	const resetAllErrors = () => {
		setPaymentNameError(false);
		setPaymentDescriptionError(false);
		setPaymentInvoiceTextUnpaidError(false);
		setPaymentInvoiceTextPaidError(false);
		setPaymentDaysError(false);
		setPaymentAllowGirocodeError(false);
	}

	useEffect(() => {
		setShowLoader(true);
		axios.get(`${process.env.REACT_APP_API_URL}/api/payment-methods/${id}`)
			.then((res) => {
				setShowLoader(false);
				if (res.data) {
					setPaymentName(res.data.payment_method_name);
					setPaymentDescription(res.data.payment_method_description);
					setPaymentInvoiceTextUnpaid(res.data.payment_method_invoice_text);
					setPaymentInvoiceTextPaid(res.data.payment_method_paid_invoice_text);
					setPaymentDirectPayment(res.data.payment_method_direct_payment === 1 ? true : false);
					setPaymentDays(res.data.payment_method_payment_days || 7);
					setPaymentAllowGirocode(res.data.payment_method_allow_girocode === 1 ? true : false);
				} else {
					navigate.push("/status/error");
				}
			})
			.catch((err) => {
				navigate.push("/status/error");
			})
	}, [id])

	const createPaymentMethod = () => {

		resetAllErrors();
		setShowLoader(true);

		/*
			REQUIRED FIELDS:
			paymentName
			paymentDescription
			paymentInvoiceTextUnpaid
			paymentInvoiceTextPaid
			paymentDays
			paymentAllowGirocode
		*/		

		/*
			Possible errors:
			paymentNameError (max 60 characters, required)
			paymentDescriptionError (max 255 characters, optional)
			paymentInvoiceTextUnpaidError (max 255 characters, required)
			paymentInvoiceTextPaidError (max 255 characters, required)
			paymentDaysError (required, must be a number, min 0, max 365)
			paymentAllowGirocodeError (required, must be a boolean)
		*/
		if (paymentName.length === 0 || paymentName.length > 60) {
			setPaymentNameError(true);
			setShowLoader(false);
			return;
		}
		if (paymentDescription.length > 255) {
			setPaymentDescriptionError(true);
			setShowLoader(false);
			return;
		}
		if (paymentInvoiceTextUnpaid.length === 0 || paymentInvoiceTextUnpaid.length > 255) {
			setPaymentInvoiceTextUnpaidError(true);
			setShowLoader(false);
			return;
		}
		if (paymentInvoiceTextPaid.length === 0 || paymentInvoiceTextPaid.length > 255) {
			setPaymentInvoiceTextPaidError(true);
			setShowLoader(false);
			return;
		}
		if (isNaN(paymentDays) || paymentDays < 0 || paymentDays > 365) {
			setPaymentDaysError(true);
			setShowLoader(false);
			return;
		}
		if (paymentAllowGirocode !== true && paymentAllowGirocode !== false) {
			setPaymentAllowGirocodeError(true);
			setShowLoader(false);
			return;
		}

		if (
			!paymentNameError &&
			!paymentDescriptionError &&
			!paymentInvoiceTextUnpaidError &&
			!paymentInvoiceTextPaidError &&
			!paymentDaysError &&
			!paymentAllowGirocodeError
		) {
			axios.put(`${process.env.REACT_APP_API_URL}/api/payment-methods/${id}`, {
				payment_method_name: paymentName,
				payment_method_description: paymentDescription,
				payment_method_invoice_text: paymentInvoiceTextUnpaid,
				payment_method_paid_invoice_text: paymentInvoiceTextPaid,
				payment_method_direct_payment: paymentDirectPayment,
				payment_method_payment_days: paymentDays,
				payment_method_allow_girocode: paymentAllowGirocode
			}).then((res) => {
				setShowLoader(false);
				navigate.push("/default-processes/verwaltung/zahlungsmethoden");
			})
		}

	}

	return (
		<>
			{showLoader && (
				<Loader />
			)}
			<Content>
				<ProcessInformationBanner
					image="developer-1.jpg"
					title="Verwaltung"
					text="Verwalten Sie Zahlungsmethoden in Ihrem Unternehmen."

					hasCancelButton={true}
					cancelButtonAction={() => {
						navigate.push("/default-processes/verwaltung/zahlungsmethoden");
					}}

					actionButtons={[
						{
							title: "Zahlungsmethode löschen",
							action: () => {
								setShowDeleteModal(true);
							}
						}
					]}

					hasSubmitButton={true}
					submitButtonTitle="Speichern"
					submitButtonAction={() => {
						createPaymentMethod()
					}}
				/>

				<ProcessSideContent>
					<ContentBox
						title="Daten"
					>
						<FormInput
							label="Name"
							value={paymentName}
							onChange={(e) => {
								setPaymentName(e.target.value);
							}}
							required
							error={paymentNameError}
						/>
						<FormInput
							label="Beschreibung"
							value={paymentDescription}
							onChange={(e) => {
								setPaymentDescription(e.target.value);
							}}
							error={paymentDescriptionError}
						/>
						<FormInput
							label="Rechnungstext unbezahlt"
							value={paymentInvoiceTextUnpaid}
							onChange={(e) => {
								setPaymentInvoiceTextUnpaid(e.target.value);
							}}
							required
							error={paymentInvoiceTextUnpaidError}
						/>
						<FormInput
							label="Rechnungstext bezahlt"
							value={paymentInvoiceTextPaid}
							onChange={(e) => {
								setPaymentInvoiceTextPaid(e.target.value);
							}}
							required
							error={paymentInvoiceTextPaidError}
						/>
						<FormCheckbox
							label="Direkte Zahlung"
							checked={paymentDirectPayment}
							onChange={(e) => {
								setPaymentDirectPayment(e.target.checked);
							}}
							text="Zahlungen werden direkt abgewickelt und nicht über ein Zahlungsziel."
						/>
						<FormCheckbox
							label="Girocode erlauben"
							checked={paymentAllowGirocode}
							onChange={(e) => {
								setPaymentAllowGirocode(e.target.checked);
							}}
							text="Erlauben Sie die Verwendung von Girocode für diese Zahlungsmethode."
						/>
						<FormInput
							label="Zahlungsfrist (Tage)"
							value={paymentDays}
							onChange={(e) => {
								setPaymentDays(e.target.value);
							}}
							required
							error={paymentDaysError}
							type="number"
						/>
					</ContentBox>
				</ProcessSideContent>
			</Content>

			{showDeleteModal && (
				<Modal
					title="Zahlungsmethode löschen"
					isClosable={true}
					closeAction={() => {
						setShowDeleteModal(false);
					}}

					actionButtons={[
						{
							title: "Löschen",
							type: "danger",
							action: () => {
								setShowLoader(true);

								axios.delete(`${process.env.REACT_APP_API_URL}/api/payment-methods/` + id).then((res) => {
									setShowLoader(false);
									if (res.data.status === "success") {
										setShowLoader(false);
										navigate.push("/status/success");
									} else {
										setShowLoader(false);
										navigate.push("/status/error");
									}
								})
							}
						}
					]}
				>
					<p>
						Möchten Sie die Zahlungsmethode wirklich löschen? Rechnungen, die mit dieser Zahlungsmethode erstellt wurden, bleiben unverändert.
					</p>
				</Modal>
			)}
		</>
	);
}

export default DefaultProcessesVerwaltungZahlungsmethodenDetailsView;