import React, { useState, useCallback, useId } from "react";
import { useEffect } from "react";

// Styles
import "./styles.scss";

function FormInput(props) {
  const htmlForId = useId();
  const [autoCompleteFound, setAutoCompleteFound] = useState(false);
  const [autoCompleteFoundItems, setAutoCompleteFoundItems] = useState([]);

  const maskDate = (event) => {
    let v = event.target.value.replace(/\D/g, "").slice(0, 10);
    let returner = v;
    if (v.length >= 4) {
      returner = `${v.slice(0, 2)}.${v.slice(2, 4)}.${v.slice(4)}`;
    } else if (v.length >= 2) {
      returner = `${v.slice(0, 2)}.${v.slice(2)}`;
    }
    event.target.value = returner.slice(0, 10);
    return event;
  };

  const maskPrice = (event) => {
    let value = event.target.value;
    value = value.replaceAll("€", "");
    value = value.replaceAll(" ", "");

    if (value !== "" && !value.includes("NaN")) {
      value = value.replaceAll(" EUR", "");
      value = value.replaceAll(" €", "");
      value = value.replaceAll(".", "");
      value = value.replaceAll(",", ".");
      value = value.replaceAll("&nbsp;", " ");

      // Create our number formatter.
      var formatter = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      });

      event.target.value = formatter.format(value.replaceAll(",", "."));

      if (formatter.format(value.replaceAll(",", ".")).includes("NaN")) {
        event.target.value = "0,00 €";
      }

      return event;
    }

    return event;
  };

  const onKeyUpAutoComplete = useCallback(
    (value) => {
      if (props.autoCompleteData) {
        let found = false;
        let items = [];

        props.autoCompleteData.forEach((item) => {
          if (value === "") {
            found = false;
          } else {
            if (item.label.toLowerCase().includes(value.toLowerCase())) {
              found = true;
              items.push(item);
            }
          }
        });

        setAutoCompleteFound(found);
        setAutoCompleteFoundItems(items);
      }
    },
    [props.autoCompleteData]
  );

  useEffect(() => {
    onKeyUpAutoComplete(props.value);
  }, [props.autoCompleteData, props.value, onKeyUpAutoComplete]);

  return (
    <div className={`FormInput${props.minimal ? " FormInput--minimal" : ""}`}>
      <input
        value={props.value}
        onKeyDown={(event) => {
          if (props.formatdate) {
            let key = event.keyCode || event.charCode;
            let v = event.target.value;
            if (key === 8 || key === 46) {
              // Get last character of the string
              let lastChar = v.slice(-1);
              if (lastChar === ".") {
                v = v.slice(0, -1);
                event.target.value = v;
              }
              return;
            }
          }
        }}
        onKeyUp={(event) => {
          onKeyUpAutoComplete(event.target.value);
        }}
        onChange={(event) => {
          if (props.formatdate) {
            props.customOnChange(maskDate(event));
          } else {
            props.customOnChange(event);
          }
        }}
        onFocus={(event) => {
          if (props.autoCompleteData) {
            let found = false;
            let items = [];
            props.autoCompleteData.forEach((item) => {
              if (event.target.value === "") {
                found = false;
              } else {
                if (
                  item.label
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase())
                ) {
                  found = true;
                  items.push(item);
                }
              }
            });

            setAutoCompleteFound(found);
            setAutoCompleteFoundItems(items);
          }
        }}
        onBlur={(event) => {
          if (props.formatprice) {
            props.onChange(maskPrice(event));
          }

          if (props.inputblur) {
            props.inputblur(event);
          }

          setTimeout(() => {
            setAutoCompleteFound(false);
          }, 1000);
        }}
        autoComplete={props.autoCompleteData ? "nope" : "on"}
        required={props.required}
        disabled={props.disabled}
        type={props.type ? props.type : "text"}
        className={`
          FormInput-Input
          ${!props.label ? " FormInput-Input--noLabel" : ""}
          ${props.error ? "FormInput-Input--error" : ""}
          ${autoCompleteFound ? "FormInput-Input--auto-complete" : ""}
        `}
        id={htmlForId}
        {...props}
        placeholder=""
      />
      {props.label && (
        <label
          htmlFor={htmlForId}
          className={`
            FormInput-Label
            ${props.value?.length > 0 ? "FormInput-Label--active" : ""}
          `}
        >
          {props.label}
          {!props.required ? " (optional)" : ""}
        </label>
      )}
      {props.disabled && (
        <span
          className={`
          material-symbols-rounded
          FormInput-DisabledIcon
          ${!props.label ? " FormInput-DisabledIcon--noLabel" : ""}
        `}
        >
          lock
        </span>
      )}
      {autoCompleteFound && (
        <div className="FormInput-AutoComplete">
          {autoCompleteFoundItems.map((item) => (
            <div
              className="FormInput-AutoComplete-Item"
              onClick={() => {
                props.onChange({
                  target: {
                    value: item.label,
                  },
                });
                if (item.onSelect) {
                  item.onSelect();
                }
                setAutoCompleteFound(false);
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default FormInput;
